<template>
  <v-card
    class="mx-auto pa-8"
    max-width="700"
    outlined
  >
    <v-card-title class="d-flex justify-center"> Регистрация </v-card-title>

    <v-form ref="registrationForm">
      <v-text-field
        v-model="registrationData.name"
        label="Введите Имя"
        required
        :error-messages="errors.name"
      />

      <v-text-field
        v-model="registrationData.email"
        label="Введите Email"
        required
        :error-messages="errors.email"
      />

      <v-text-field
        v-model="registrationData.password"
        label="Придумайте пароль"
        required
        type="password"
        :error-messages="errors.password"
      />

      <v-card-text class="pa-0 mb-4 red--text">
        {{ singleError }}
      </v-card-text>

      <v-card-actions class="px-0">
        <v-btn
          color="red"
          class="mr-2 text-none"
          :loading="loading"
          @click="signUp"
        >
          Зарегестрироваться
        </v-btn>

        <v-btn
          text
          class="text-none"
          to="/login"
        >
          Вход
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import AuthService from '@/app/Services/AuthService'

export default {
  data: () => ({
    loading: false,
    registrationData: {
      name: '',
      email: '',
      password: '',
    },
    errors: {
      name: [],
      email: [],
      password: [],
    },
  }),

  computed: {
    singleError() {
      return typeof this.errors === 'string' ? this.errors : ''
    },
  },

  methods: {
    async signUp() {
      this.loading = true

      const { status, message } = await AuthService.signUp(this.registrationData)

      if (status) {
        this.$router.push('/')
      } else {
        this.errors = message
      }

      this.loading = false
    },
  },
}
</script>
